let workPlace = window.location.hostname;
let baseUrlWithoutProxy = '';
let googleMapsKey = '';
let customURL = '';
let googlePlacesKey = '';
let proxy = 'https://cors-anywhere.herokuapp.com/';
/*
    @ change the name of the hotel for each hotel app project name 
    @ list of hotels
        - MeliaCasteloBranco
        - MeliaBraga
        - MeliaRia
        - MeliaSetubal
        - MeliaMaputoSky
        - MeliaMadeiraMare
*/

// Main Build Hotel Name 
// InnsideMeliaBraga
const hotel = "InnsideMeliaBraga";

if (hotel === "InnsideMeliaBraga") {
    baseUrlWithoutProxy = "https://www.innsidebraga.com/";
    document.title = "INNSiDE by Meliã Braga Centro";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "innside_melia_braga"    
} 

if (workPlace.includes('localhost') || workPlace.includes('dev2') ) {
    googleMapsKey = 'AIzaSyC2pUy3WMzxafMKzE78pKi-swUpm-P_wJY';
    googlePlacesKey = 'AIzaSyC2pUy3WMzxafMKzE78pKi-swUpm-P_wJY';
} 

const baseUrl = proxy + baseUrlWithoutProxy;
const prefix = 'cc_';
const apiPath = "wp-json/app/";
const apiNewPath = "hoti/";
const apiFunction = "?f=";
const apiHotel = "&h=";
const apiLang = "&lang=";
const apiID = "&id=";
const apiG_ID = "&g_id=";

const Global = {
    proxy: proxy,
    websiteBaseUrlWithoutProxy: baseUrlWithoutProxy,
    websiteBaseUrl: baseUrl,
    apiPath: apiPath,
    customURL: customURL,
    apiNewPath: apiNewPath,
    apiFunction: apiFunction,
    apiHotel: apiHotel,
    apiLang: apiLang,
    apiID: apiID,
    apiG_ID: apiG_ID,
    websiteUrl: baseUrl,
    googleMapsRouteUrl: 'https://www.google.com/maps/dir',
    googleMapsAPIKey: googleMapsKey,
    googlePlacesAPIKey: googlePlacesKey,
    googleMapsKey: 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsKey + '&v=3.exp&libraries=geometry,drawing,places',
    googleMapsWithCoordinates: 'https://www.google.com/maps/place/',
    localStorage: {
        generalSettings: prefix + 'generalSettings',
        googleDataSettings: 'googleDataSettings'
    }
};

export default Global;